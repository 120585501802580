<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator">
      <app-detail-header :show-toggle="true"
        :show-new="false"
        :show-print="false"
        :show-delete="false"
        :show-save="showSave"
        :show-cancel="false"
        @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
      <div class="columns is-gapless">
        <div class="column is-narrow">
          <company-setting-side-menu />
        </div>
        <div class="column">
          <router-view ref="currentChild"
            v-model="entity"
            :is-new.sync="isNew"
            :fiscal-year-list="fiscalYearList"
            :fiscal-year.sync="fiscalYear"
            :fiscal-start.sync="fiscalStart"
            :fiscal-duration.sync="fiscalDuration"
            :target-mode.sync="targetMode"
            :daily-target.sync="dailyTarget"
            :next-fiscal-year="nextFiscalYear"
            @action-changed="checkUnsave"
            @create-fy="checkUnsave('create')"
            @cancel-fy="checkUnsave('cancel')"
            @delete-fy="deleteFY" />
        </div>
      </div>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="save(true)"
      @cancel="cancel"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete FY{{ fiscalYear }}</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">FY{{ fiscalYear }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
  </div>
</template>

<script>
import HttpStatus from '@/components/http-status'
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import CompanySettingSideMenu from './CompanySettingSideMenu'
import { CompanySettingService, CompanyMonthlySettingService } from '@/services'
import { UnsavedModal, ConfirmModal } from '@/components/BulmaModal'
import { getFiscalYearName, getFiscalYearDates, addMonthsToDate } from '@/components/utils/CalendarFunctions'
import { CompanyMonthlySettingModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'
import DeepDiff from 'deep-diff'

export default {
  name: 'CompanySettingView',
  components: {
    AppDetailHeader,
    CompanySettingSideMenu,
    ConfirmModal,
    UnsavedModal
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      entity: null,
      oriEntity: null,
      oriTargetMode: 'Daily',
      oriFiscalStart: 0,
      oriFiscalDuration: 0,
      fiscalYearList: [],
      fiscalYear: null,
      fiscalStart: 0,
      fiscalDuration: 0,
      nextFiscalYear: null,
      dailyTarget: 0,
      targetMode: 'Daily',
      isNew: false,
      isSkipSave: false,
      isSaveContinue: false,
      isConfirmModalActive: false,
      isUnsavedModalActive: false,
      action: null,
      toRoute: null
    }
  },
  computed: {
    showSave() {
      if ((this.entity && this.entity.length !== 0) || this.fiscalYearList.length === 0) {
        return true
      } else {
        return false
      }
    },
    isDeepDiffFiscalSetting() {
      if (DeepDiff.diff(this.oriFiscalStart, this.fiscalStart) || DeepDiff.diff(this.oriFiscalDuration, this.fiscalDuration)) {
        return true
      } else {
        return false
      }
    },
    isDeepDiffMonthlySetting() {
      if (DeepDiff.diff(this.oriEntity, this.entity) || (DeepDiff.diff(this.oriTargetMode, this.targetMode) && !this.isNew)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    fiscalStart(newVal, oldVal) {
      if (newVal !== oldVal)
        this.getNextFiscalYear()
    },
    fiscalDuration(newVal, oldVal) {
      if (newVal !== oldVal)
        this.getNextFiscalYear()
    }
  },
  created() {
    this.getSetting()
    this.getFiscalYearList()
    this.getCurrentFiscalYear()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.checkUnsave('create')
          break
        case AppHeaderButtonTypes.Cancel:
          this.checkUnsave('cancel')
          break
        case AppHeaderButtonTypes.Delete:
          this.deleteFY()
          break
        case AppHeaderButtonTypes.Save:
          if (this.isDeepDiffFiscalSetting) {
            this.saveCompanySetting()
          } else {
            this.save(false)
          }
          break
      }
    },
    async getSetting() {
      this.oriFiscalStart = this.$company.setting.fiscalStart
      this.oriFiscalDuration = this.$company.setting.fiscalDuration
      this.fiscalStart = this.$company.setting.fiscalStart
      this.fiscalDuration = this.$company.setting.fiscalDuration
    },
    async getFiscalYearList() {
      this.fiscalYearList = await CompanyMonthlySettingService.getFiscalYearList()
      this.getNextFiscalYear()
    },
    async getCurrentFiscalYear() {
      const currentMonth = new Date().getMonth() + 1 // JavaScript months are zero-indexed
      const currentYear = new Date().getFullYear()
      this.fiscalYear = await CompanyMonthlySettingService.checkExists(currentYear, currentMonth)
      if (this.fiscalYear !== null)
        await this.getFiscalSetting()
    },
    async getNextFiscalYear() {
      if (this.fiscalYearList.length !== 0) {
        const [lastMonth, lastYear] = await this.getLastFiscalYear()
        const lastFY = new Date(lastYear, lastMonth-1) // zero indexed for month
        const fiscalDateStart = addMonthsToDate(lastFY, 1)
        this.nextFiscalYear = getFiscalYearName(fiscalDateStart, this.fiscalDuration)
      } else {
        // get fiscal date start based on current date
        const [fiscalDateStart] = await getFiscalYearDates(this.fiscalStart, this.fiscalDuration, true)
        this.nextFiscalYear = getFiscalYearName(fiscalDateStart, this.fiscalDuration)
      }
    },
    async getLastFiscalYear() {
      const lastFY = this.fiscalYearList[this.fiscalYearList.length - 1]
      const lastFYEntity = await CompanyMonthlySettingService.getCompanyMonthlySettingByFY(lastFY)
      const lastRecord = lastFYEntity[lastFYEntity.length - 1]
      const lastYear = lastRecord.year
      const lastMonth = lastRecord.month

      return [lastMonth, lastYear]
    },
    checkUnsave(action) {
      this.action = action
      if (this.entity && (this.isDeepDiffFiscalSetting || this.isDeepDiffMonthlySetting || this.isNew)) {
        this.isUnsavedModalActive = true
      } else if (this.isDeepDiffFiscalSetting) {
        this.isUnsavedModalActive = true
      } else {
        switch (action) {
          case 'create':
            this.isNew = true
            this.createFiscalYear()
            break
          case 'update':
            this.getFiscalSetting()
            break
          case 'cancel':
            this.reset()
            break
        }
      }
    },
    async getFiscalSetting() {
      this.$showSpinner('Loading...')
      this.entity = await CompanyMonthlySettingService.getCompanyMonthlySettingByFY(this.fiscalYear)

      if (this.entity && this.entity.length > 0) {
        if (this.entity[0].dailyTarget === null) {
          this.targetMode = 'Yearly'
          this.dailyTarget = 0
        } else {
          this.targetMode = 'Daily'
          this.dailyTarget = this.entity[0].dailyTarget
        }
        this.oriTargetMode = this.targetMode
      }
      this.oriEntity = _cloneDeep(this.entity)
      this.isNew = false
      this.$hideSpinner()
    },
    reset() {
      this.isNew = false
      this.entity = null
      this.oriEntity = this.entity
      this.targetMode = 'Daily'
      this.oriTargetMode = 'Daily'
      this.fiscalYear = null
      this.action = null
      this.dailyTarget = 0
    },
    closeModal() {
      if (!this.isSkipSave && !this.isSaveContinue && this.action !== 'routeLeave') {
        this.fiscalYear = this.entity ? this.entity[0].fiscalYearName.toString() : null
      }
      this.isConfirmModalActive = false
      this.isUnsavedModalActive = false
      this.isSaveContinue = false
      this.isSkipSave = false
    },
    cancel() {
      this.closeModal()
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        this.toRoute = null
      }
    },
    deleteFY() {
      this.isConfirmModalActive = true
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true

      if (this.isDeepDiffFiscalSetting) {
        this.fiscalStart = this.oriFiscalStart
        this.fiscalDuration = this.oriFiscalDuration
      }

      if (this.isDeepDiffMonthlySetting) {
        this.entity = _cloneDeep(this.oriEntity)
      }

      if (this.action === 'create') {
        this.createFiscalYear()
      } else if (this.action === 'cancel') {
        this.reset()
      } else {
        this.cancel()
        if (this.action !== 'routeLeave') this.getFiscalSetting()
      }
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      switch (this.action) {
        case 'create':
          if (this.isDeepDiffFiscalSetting) {
            this.isSaveContinue = await this.saveCompanySetting()
          }
          this.createFiscalYear()
          break
        case 'update':
          this.isSaveContinue = await this.save()
          this.getFiscalSetting()
          this.cancel()
          break
        case 'routeLeave':
          if (this.isDeepDiffFiscalSetting) {
            this.isSaveContinue = await this.saveCompanySetting()
          } else {
            this.isSaveContinue = await this.save()
          }
          if (this.isSaveContinue) {
            this.cancel()
          }
          break
        case 'cancel':
          this.isSaveContinue = await this.save()
          this.reset()
          break
      }
      this.isSaveContinue = false
    },
    async saveCompanySetting() {
      try {
        this.$showSpinner('Saving...')
        let response
        response = await CompanySettingService.UpdateFiscalSetting(this.fiscalStart, this.fiscalDuration)

        if (response.status === HttpStatus.NO_CONTENT) {
          this.$notification.success('Company Setting', 'Monthly setting saved successfully')
        }
        this.oriFiscalStart = this.fiscalStart
        this.oriFiscalDuration = this.fiscalDuration
        return true
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
        return false
      } finally {
        await this.$store.dispatch('company/getCompanySetting')
        this.$hideSpinner()
      }
    },
    async save(isDelete) {
      try {
        this.$showSpinner('Saving...')
        let response

        if (!this.isNew && !this.isDeepDiffFiscalSetting && !this.isDeepDiffMonthlySetting && !isDelete) {
          this.$notification.openNotificationWithType('warning', 'Company Setting', 'No changes. Not saved')
          return false
        }

        if (this.entity) {
          if (this.targetMode === 'Yearly') {
            this.entity.forEach((i) => (i.dailyTarget = null))
          }
          if (this.isNew) {
            response = await CompanyMonthlySettingService.postEntity(this.entity)
          } else {
            if (isDelete) {
              this.entity.forEach((i) => {
                i.isDeleted = true
              })
            }
            response = await CompanyMonthlySettingService.putEntity(this.entity[0].fiscalYear, _cloneDeep(this.entity))
          }
          if (response.status === HttpStatus.CREATED) {
            this.$notification.success('Company Setting', 'Fiscal Year ' + this.fiscalYear + ' created')
            this.entity = await CompanyMonthlySettingService.getCompanyMonthlySettingByFY(this.fiscalYear)
            this.fiscalYearList.push(this.fiscalYear)
            this.getNextFiscalYear()
          } else if (response.status === HttpStatus.NO_CONTENT) {
            this.$notification.success('Company Setting', 'Fiscal Year ' + this.fiscalYear + (isDelete ? ' deleted successfully' : ' saved successfully'))

            if (isDelete) {
              let index = this.fiscalYearList.indexOf(this.fiscalYear)
              if (index !== -1) {
                this.fiscalYearList.splice(index, 1)
              }
              this.entity = null
              this.oriEntity = null
              this.targetMode = 'Daily'
              this.oriTargetMode = 'Daily'
              this.fiscalYear = null
              this.isConfirmModalActive = false
              this.getNextFiscalYear()
            }
          }
          this.isNew = false
          this.oriEntity = _cloneDeep(this.entity)
          this.oriTargetMode = this.targetMode
        }
        return true
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    async createFiscalYear() {
      this.$showSpinner()
      this.isNew = true
      let dateFrom = new Date()
      let dateTo = new Date()
      if (this.fiscalYearList.length !== 0) {
        const [lastMonth, lastYear] = await this.getLastFiscalYear()
        // javascript month is start with 0
        dateFrom = new Date(lastYear, lastMonth)
        dateTo = addMonthsToDate(dateFrom, this.fiscalDuration - 1)
      } else {
        ;[dateFrom, dateTo] = await getFiscalYearDates(this.fiscalStart, this.fiscalDuration, true)
      }
      this.fiscalYear = this.nextFiscalYear.toString()

      this.entity = []
      for (let currentDate = new Date(dateFrom); currentDate <= dateTo; currentDate.setMonth(currentDate.getMonth() + 1)) {
        // Extract month and year from the current date
        const month = currentDate.toLocaleString('default', { month: 'numeric' })
        const year = currentDate.getFullYear()
        const model = new CompanyMonthlySettingModel(this.$userInfo.companyId, this.nextFiscalYear, year, month)
        this.entity.push(model)
      }
      this.$hideSpinner()
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isDeepDiffFiscalSetting || this.isDeepDiffMonthlySetting || (this.isNew && this.entity)) && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.action = 'routeLeave'
      this.isUnsavedModalActive = true
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
