<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <div class="tile is-child box">
        <section>
          <div class="pt-2 columns">
            <div class="column">
              <div>
                <span class="subtitle">Monthly Setting</span>
              </div>
            </div>
            <div class="column">
              <div class="is-flex is-justify-content-right">
                <button class="button is-primary"
                  @click="createFiscalYear"
                  :disabled="isNew || (!isNew && innerValue && innerValue.length !== 0)">Create FY{{ nextFiscalYear }}</button>
              </div>
            </div>
          </div>
          <div class="columns pb-3">
            <div class="column is-narrow">
              <div class="field is-horizontal">
                <div class="field">
                  <label class="label">Fiscal Start Month</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="innerFiscalStart"
                        :disabled="isNew || fiscalYearList.length !== 0"
                        @change="changeFiscalStart()">
                        <option value="null"
                          disabled>MM</option>
                        <option v-for="(month, key) in months"
                          :value="key"
                          :key="key">{{ month }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <div class="field is-horizontal">
                <div class="field">
                  <label class="label">Fiscal Duration</label>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <vue-numeric class="input has-text-right"
                          v-model="innerFiscalDuration"
                          placeholder="Fiscal Duration"
                          :min="12"
                          :precision="0"
                          :disabled="isNew || fiscalYearList.length !== 0"
                          @input="updateFiscalDuration" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-narrow pr-5 mr-5">
              <div class="field is-horizontal">
                <div class="field">
                  <label class="label">Fiscal Year</label>
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="selectedFiscalYear"
                        @change="getMonthlyEntity($event.target.value)"
                        :disabled="isNew">
                        <option :value="null"
                          disabled>Select</option>
                        <option v-for="(val, key) in fiscalYearList"
                          :key="key"
                          :value="val">{{ val }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-2">
              <div class="pretty p-default p-round p-bigger p-smooth is-flex is-align-items-top mb-3">
                <input v-model="innerTargetMode"
                  id="dailyMode"
                  type="radio"
                  name="rd-innerTargetMode"
                  :value="'Daily'"
                  :disabled="!isNew && selectedFiscalYear === null"
                  @change="changeTargetMode">
                <div class="state p-primary-o">
                  <label class="label has-text-weight-bold">Daily Target</label>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="innerDailyTarget"
                      placeholder="Daily Target"
                      :min="0"
                      :precision="0"
                      :disabled="(!isNew && selectedFiscalYear === null) || innerTargetMode === 'Yearly'"
                      :symbol="$company.currency.symbol"
                      @input.native="updateDailyTarget(innerDailyTarget)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-2">
              <div class="pretty p-default p-round p-bigger p-smooth is-flex is-align-items-top mb-3">
                <input v-model="innerTargetMode"
                  id="yearlyMode"
                  type="radio"
                  name="rd-innerTargetMode"
                  :value="'Yearly'"
                  :disabled="!isNew && selectedFiscalYear === null"
                  @change="changeTargetMode">
                <div class="state p-primary-o">
                  <label style="font-size: 14px; font-weight: bold;">Yearly Target</label>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="yearlyTarget"
                      placeholder="Yearly Target"
                      :min="0"
                      :precision="0"
                      :disabled="(!isNew && selectedFiscalYear === null) || innerTargetMode === 'Daily'"
                      :symbol="$company.currency.symbol"
                      @input.native="updateYearlyTarget()" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-narrow">
              <div class="field is-horizontal">
                <div class="field">
                  <label class="label">Initial Monthly Working Days</label>
                  <div class="field-body">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="avgWorkingDays"
                        placeholder="Monthly Working Days"
                        :min="0"
                        :precision="0"
                        :disabled="!isNew && selectedFiscalYear === null"
                        @input.native="updateAvgWorkingDays(avgWorkingDays)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div>
              <button class="button is-danger mr-5"
                @click="deleteFiscalYear"
                :disabled="!showDelete">Delete</button>
              <button class="button is-primary"
                @click="cancel"
                :disabled="!showCancel">Cancel</button>
            </div>
          </div>
          <div v-if="innerValue && innerValue.length !== 0">
            <div class="is-divider" />
            <div v-show="innerValue && innerValue.length !== 0"
              class="title is-4">FY {{ innerValue[0].fiscalYearName }}</div>
            <table class="table is-striped is-narrow">
              <thead>
                <tr>
                  <th class="no-border">&nbsp;</th>
                  <th class="has-text-centered no-border">Working Days</th>
                  <th class="has-text-centered no-border">Monthly Target</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in innerValue"
                  :key="index">
                  <td class="has-text-weight-bold has-vertical-middle column-min-width no-border">
                    {{ getMonthAbbr(item.month) }} {{ item.year }}
                  </td>
                  <td class="has-vertical-middle has-text-right no-border">
                    <vue-numeric class="input has-text-right"
                      v-model="item.workingDays"
                      placeholder="Working Days"
                      :min="0"
                      :precision="0"
                      @input.native="updateMonthWorkingDays(item)" />
                  </td>
                  <td class="has-vertical-middle has-text-right money-input no-border">
                    <vue-numeric class="input has-text-right"
                      v-model="item.monthlyTarget"
                      placeholder="Monthly Target"
                      :min="0"
                      :precision="0"
                      :symbol="$company.currency.symbol"
                      :disabled="disableMonthlyTarget"
                      @input.native="updateMonthMonthlyTarget(item.monthlyTarget)" />
                  </td>
                </tr>
              </tbody>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </table>
          </div>
        </section>
      </div>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import VueNumeric from '@/components/VueNumeric'
import _cloneDeep from 'lodash/cloneDeep'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { getMonthAbbr } from '@/components/utils/CalendarFunctions'

export default {
  name: 'CompanyMonthlySetting',
  components: { VueNumeric },
  props: {
    value: null,
    isNew: {
      type: Boolean,
      value: false
    },
    fiscalYearList: {
      type: Array,
      default: () => {
        return []
      }
    },
    fiscalYear: {
      type: String,
      value: null
    },
    fiscalStart: {
      type: Number,
      value: 0
    },
    fiscalDuration: {
      type: Number,
      value: 0
    },
    nextFiscalYear: {
      type: String,
      value: null
    },
    targetMode: {
      type: String,
      value: null
    },
    dailyTarget: {
      type: Number,
      value: 0
    }
  },
  data() {
    return {
      isConfirmModalActive: false,
      innerValue: null,
      innerFiscalYear: this.fiscalYear,
      innerFiscalStart: this.fiscalStart,
      innerFiscalDuration: this.fiscalDuration,
      innerDailyTarget: this.dailyTarget,
      innerTargetMode: this.targetMode,
      selectedFiscalYear: null,
      avgWorkingDays: 0,
      yearlyTarget: 0,
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      }
    }
  },
  computed: {
    showDelete() {
      const length = this.fiscalYearList.length
      if (length !== 0) {
        const index = this.fiscalYearList.indexOf(this.fiscalYear)
        const lastIndex = length - 1
        if (index === lastIndex) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    showCancel() {
      if (this.innerValue && this.innerValue.length !== 0) {
        return true
      } else {
        return false
      }
    },
    disableMonthlyTarget() {
      if (this.innerTargetMode === 'Daily') {
        return true
      }
      return false
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        if (this.innerValue === null) this.yearlyTarget = 0
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
        this.updateMonthMonthlyTarget()
      },
      deep: true
    },
    fiscalYear(newVal) {
      this.innerFiscalYear = newVal
      if (!this.isNew && newVal === null) {
        this.reset()
      }
    },
    isNew(newVal) {
      if (newVal) {
        this.reset()
      } else {
        this.selectedFiscalYear = this.fiscalYear
      }
    },
    innerFiscalYear(newVal) {
      if (this.isNew) {
        this.selectedFiscalYear = null
      } else {
        this.selectedFiscalYear = newVal
      }
      // update on existing FY then change FY and prompt unsaved, then select close modal
      this.$emit('update:fiscal-year', newVal)
    },
    selectedFiscalYear(newVal) {
      this.innerFiscalYear = newVal
      this.$emit('update:fiscal-year', newVal)
    },
    fiscalStart(newVal) {
      this.innerFiscalStart = newVal
    },
    fiscalDuration(newVal) {
      this.innerFiscalDuration = newVal
    },
    targetMode(newVal) {
      this.innerTargetMode = newVal
    },
    dailyTarget(newVal) {
      this.innerDailyTarget = newVal
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
  },
  mounted() {},
  methods: {
    async getMonthlyEntity(fiscalYear) {
      this.avgWorkingDays = 0
      this.$emit('update:fiscal-year', fiscalYear)
      this.$emit('action-changed', 'update')
    },
    changeFiscalStart() {
      this.$emit('update:fiscal-start', parseInt(this.innerFiscalStart))
    },
    createFiscalYear() {
      this.$emit('create-fy')
    },
    deleteFiscalYear() {
      this.$emit('delete-fy')
    },
    cancel() {
      this.$emit('cancel-fy')
    },
    reset() {
      this.selectedFiscalYear = null
      this.avgWorkingDays = 0
      this.yearlyTarget = 0
      this.innerDailyTarget = 0
    },
    updateFiscalDuration() {
      this.$emit('update:fiscal-duration', parseInt(this.innerFiscalDuration))
    },
    changeTargetMode() {
      if (this.innerTargetMode === 'Daily') {
        const totalWorkingDays = this.innerValue.reduce((sum, month) => {
          sum += month.workingDays || 0
          return sum
        }, 0)

        if (totalWorkingDays !== 0) {
          const dailyTarget = this.yearlyTarget / totalWorkingDays
          this.innerDailyTarget = dailyTarget
          this.innerValue.forEach(function (month) {
            month.dailyTarget = dailyTarget
          })
        }
      }
      this.$emit('update:target-mode', this.innerTargetMode)
      this.$emit('update:daily-target', this.innerDailyTarget)
    },
    updateDailyTarget(innerDailyTarget) {
      this.innerValue.forEach(function (month) {
        month.dailyTarget = innerDailyTarget
        month.monthlyTarget = month.workingDays * innerDailyTarget
      })
      this.$emit('update:daily-target', innerDailyTarget)
    },
    updateYearlyTarget() {
      const totalWorkingDays = this.innerValue.reduce((sum, month) => {
        sum += month.workingDays || 0
        return sum
      }, 0)

      if (totalWorkingDays !== 0) {
        const innerDailyTarget = roundAwayFromZero(this.yearlyTarget / totalWorkingDays, 0)
        this.innerDailyTarget = 0
        this.innerValue.forEach(function (month) {
          month.dailyTarget = null
          month.monthlyTarget = month.workingDays * innerDailyTarget
        })
      }
    },
    updateAvgWorkingDays(avgWorkingDays) {
      if (this.innerTargetMode === 'Daily') {
        this.innerValue.forEach((i) => {
          i.workingDays = avgWorkingDays
          i.monthlyTarget = avgWorkingDays * this.innerDailyTarget
        })
      } else {
        this.innerValue.forEach((i) => {
          i.workingDays = avgWorkingDays
        })
        this.updateYearlyTarget()
      }
    },
    updateMonthWorkingDays(item) {
      if (this.innerTargetMode === 'Daily') {
        item.monthlyTarget = item.workingDays * this.innerDailyTarget
      }
    },
    updateMonthMonthlyTarget() {
      if (this.innerValue) {
        this.yearlyTarget = this.innerValue.reduce((sum, month) => {
          sum += month.monthlyTarget || 0
          return sum
        }, 0)
      }
    },
    getMonthAbbr(number) {
      return getMonthAbbr(number)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-border {
  border: none;
}
.column-min-width {
  min-width: 8em;
}
</style>
